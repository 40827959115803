<template>
  <div>

    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Pengeluaran Apotek'">
          <template v-slot:body>
            <Form :form="form" route="pharmacy-expenses" />
          </template>
        </Card>
      </div>
    </div>

  </div>
</template>

<script>

import Card from '@/view/content/Card.vue'
import Form from '@/component/pharmacy/pharmacy-expenses/Form.vue'
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module"

export default {

  components: {
    Card,
    Form
  },

  data() {
    return {
      // Form
      form: {
        date: new Date().toISOString().substr(0, 10),
        name: '',
        amount: '',
        pharmacy_expense_category_id: '',
        expense_method: '1',
        expense_method_type: '1',
        notes: '',
        photo: [],
        photo_name: "/images/default-expense.svg"
      },
    }
  },

  mounted() {
    // BreadCrumb
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Apotek", route:"/pharmacy/dashboard" },
      { title: "Pengeluaran", route: "/pharmacy/pharmacy-expenses/list" },
      { title: "Tambah Pengeluaran" },
    ])
  },

}
</script>

<style>
</style>